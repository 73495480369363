<template>
<div>
    <v-data-table :loading="loadingTable" :headers="headers" :items="services" class="elevation-1" :page-count="numberOfPagesPaginate" :page="currentPagePaginate" :server-items-length="totalStagesPaginate" :options.sync="options" :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>Serviços</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark rounded class="mb-2" v-bind="attrs" v-on="on">
                            <v-icon small>
                                {{ icons.mdiPlus }}
                            </v-icon>
                            Novo Serviço
                        </v-btn>
                    </template>
                    <v-card>
                        <div class="title_body">
                            <span class="text_title">{{ formTitle }}</span>
                            <span class="btn_close">
                                <v-btn icon small @click="dialog = false">
                                    <v-icon dark> {{ icons.mdiClose }} </v-icon>
                                </v-btn>
                            </span>
                        </div>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12">
                                        <v-text-field v-model="editedItem.description" outlined label="Serviço"></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col>
                                        <vuetify-money v-model="editedItem.sale_price" outlined label="Preço de venda"></vuetify-money>
                                    </v-col>
                                    <v-col>
                                        <vuetify-money :options="{ locale: 'pt-BR',suffix:'%',precision:2}"  v-model="editedItem.iss" outlined label="ISS" class="ml-2"></vuetify-money>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn x-large color="secondary" text @click="close">
                                Fechar
                            </v-btn>
                            <v-btn x-large :loading="loading" color="primary" @click="save">
                                Salvar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.sale_price="{ item }">
          {{ formatMoney(item.sale_price)  }}
        </template>
        <template v-slot:item.actions="{ item }">

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="primary" class="mr-2" @click="editItem(item)" v-bind="attrs" v-on="on">
                    {{ icons.mdiPencilOutline }}
                </v-icon>
              </template>
            <span>Editar</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon color="error" @click="deleteItem(item)" v-bind="attrs" v-on="on">
                    {{ icons.mdiDeleteOutline }}
                </v-icon>
              </template>
            <span>Excluir</span>
            </v-tooltip>

            <!-- <v-icon color="primary" class="mr-2" @click="editItem(item)">
                {{ icons.mdiPencilOutline }}
            </v-icon>
            <v-icon color="error" @click="deleteItem(item)">
                {{ icons.mdiDeleteOutline }}
            </v-icon> -->
        </template>
        <template v-slot:no-data>
            <NoDataTable></NoDataTable>
        </template>
        <template v-slot:loading>
            Carregando...
        </template>
    </v-data-table>
    <MsgDelete v-model="dialogDelete" @delete-yes="deleteItemConfirm()" />
</div>
</template>

<script>
import MsgDelete from '@/components/MsgDelete.vue'
import NoDataTable from '@/components/NoDataTable.vue'
import {
    mdiClose,
    mdiDeleteOutline,
    mdiPencilOutline,
    mdiPlus
} from '@mdi/js'
import {
    mapActions
} from 'vuex'

export default {
    components: {
        MsgDelete,
        NoDataTable,
    },
    data: () => ({
        loadingTable: false,
        totalStagesPaginate: 0,
        numberOfPagesPaginate: 0,
        currentPagePaginate: 0,
        dialogDelete: false,
        dialog: false,
        loading: false,
        headers: [{
                text: 'ID',
                align: 'start',
                value: 'id',
            },
            {
                text: 'Categoria',
                value: 'description'
            },
            {
                text: 'Preço de Venda',
                align:'right',
                value: 'sale_price'
            },

            {
                text: '',
                align: 'end',
                value: 'actions',
                sortable: false,
            },
        ],
        services: [],
        editedIndex: -1,
        editedItem: {

        },
        defaultItem: {
            description: '',
            sale_price:''
        },
        options: {},
        icons: {
            mdiPencilOutline,
            mdiDeleteOutline,
            mdiPlus,
            mdiClose,
        },
    }),

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Novo Serviço ' : 'Editar Serviço'
        },
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
        options: {
            handler() {
                this.initialize()
            },
        },
    },

    created() {
        this.initialize()
    },

    methods: {
        ...mapActions('service', ['getAll', 'store', 'update', 'delete']),
        initialize() {
            this.loadingTable = true

            const {
                page,
                itemsPerPage
            } = this.options

            const payload = {
                itemsPerPage: itemsPerPage || 10,
                page,
                search: this.search,
            }

            this.getAll(payload)
                .then(response => {
                    this.services = response.data.data
                    this.totalStagesPaginate = response.data.total
                    this.numberOfPagesPaginate = response.data.last_page
                    this.currentPagePaginate = response.data.current_page
                })
                .finally(() => {
                    this.loadingTable = false
                })
        },

        editItem(item) {
            this.editedIndex = this.services.indexOf(item)
            this.editedItem = {
                ...item
            }
            this.dialog = true
        },

        deleteItem(item) {
            this.dialogDelete = true
            this.editedItem = item
        },

        deleteItemConfirm() {
            const index = this.services.indexOf(this.editedItem)
            this.delete(this.editedItem)
                .then(() => {
                    this.services.splice(index, 1)
                    this.closeDelete()
                    this.$toast.success('Excluído com sucesso !')
                })
                .catch()
                .finally()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }
                this.editedIndex = -1
            })
        },
        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = {
                    ...this.defaultItem
                }
                this.editedIndex = -1
            })
        },
        save(){
            this.loading = true
            if (this.editedIndex > -1) {
                const index = this.editedIndex
                this.update(this.editedItem)
                    .then(response => {
                        const category = response.data
                        Object.assign(this.services[index], category)
                        this.close()
                        this.$toast.success('Salvo com sucesso !')
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            } else {
                this.store(this.editedItem)
                    .then(response => {
                        const service = response.data
                        this.services.push(service)
                        this.close()
                        this.$toast.success('Salvo com sucesso !')
                    })
                    .catch()
                    .finally(() => {
                        this.loading = false
                    })
            }
            //gabiarra pra superar o bug de nao mostra table com 1 item
            if( this.services.length == 1){
              this.initialize()
            }


        },
        formatMoney(value) {
            if (value != null) {
                return value.toLocaleString('pt-br', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
        },
    },
}
</script>
